/* Welcome */
.btp-welcome {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;

  .btp-h1 {
    line-break: anywhere;
  }

  .btp-p {
    color: var(--btp-color-neutral-500);
  }

  &__user-data {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  @media (min-width: 880px) {
    padding: 0;
  }
}



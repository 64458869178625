:root {
  --btp-mobile-breakpoint: 880px;

  --btp-transition-duration-short: 0.1s;
  --btp-transition-duration-medium: 0.2s;

  --btp-color-primary-400: #E57200;
  --btp-color-primary-500: #F99C25;
  --btp-color-neutral-0: #000;
  --btp-color-neutral-300: #333;
  --btp-color-neutral-500: #6A6A6A;
  --btp-color-neutral-600: #979797;
  --btp-color-neutral-700: #F5F5F5;
  --btp-color-neutral-800: #FAFAFA;
  --btp-color-neutral-900: #FFF;

  --btp-color-accent-green-500: rgba(34, 197, 94, 1);

  --btp-font-heading: 'Montserrat', sans-serif;
  --btp-font-body: 'Open Sans', sans-serif;

  --btp-element-padding-small-mobile: 40px 4px;
  --btp-element-padding-small-tablet: 40px 14px;
  --btp-element-padding-small: 40px 24px;
  --btp-element-padding-medium-mobile: 40px 20px;
  --btp-element-padding-medium-tablet: 40px 30px;
  --btp-element-padding-medium: 40px 40px;
  --btp-element-border-radius: 8px;
  --btp-element-box-shadow: 0 4px 40px 0 rgba(16, 24, 40, 0.03);
  --btp-element-gradient: linear-gradient(90deg, var(--btp-color-primary-400) 0%, var(--btp-color-primary-500) 100%);
}

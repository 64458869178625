/* Recent News */
.btp-latest-news {
  &__list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
    }
  }

  &__list-item {
    margin: 0;
    border-top: 1px solid transparent;
    transition: border-color var(--btp-transition-duration-short) ease-in-out;

    &:not(:first-of-type) {
      border-color: var(--btp-color-neutral-800);
    }

    &:hover,
    &:focus-within {
      border-color: transparent;

      + .btp-latest-news__list-item {
        border-color: transparent;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 8px;
    border-radius: var(--btp-element-border-radius);
    padding: 16px;
    color: var(--btp-color-primary-500);
    background: transparent;
    cursor: pointer;
    text-decoration: none;
    transition: background-color var(--btp-transition-duration-short) ease-in-out,
    border-radius var(--btp-transition-duration-short) ease-in-out,
    color var(--btp-transition-duration-short) ease-in-out;

    &:link,
    &:visited {
      color: var(--btp-color-neutral-0);
    }

    &:hover,
    &:focus-visible,
    &:focus {
      color: var(--btp-color-primary-500);
      background: var(--btp-color-neutral-800);
      cursor: pointer;
      text-decoration: none;

      + .btp-latest-news__link {
        border-color: transparent;
      }
    }
  }

  &__title {
    flex: 1 0 100%;
  }

  &__date {
    min-width: 55px;
    color: var(--btp-color-neutral-600);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    stroke-width: 1.25;
  }

  @media (min-width: 1080px) {
    &__link {
      align-items: center;
      flex-wrap: nowrap;
    }

    &__title {
      flex: auto;
    }
  }
}

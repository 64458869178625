/* Dashboard */
.btp-partners-dashboard {
  --block-gap: 20px;
  --block-padding-small: var(--btp-element-padding-small-mobile);
  --block-padding-medium: var(--btp-element-padding-medium-mobile);

  position: relative;
  overflow: hidden;
  background-color: var(--btp-color-neutral-800) !important;

  &__block {
    border: 1px solid var(--btp-color-neutral-700);
    padding: var(--block-padding-small);
    background: var(--btp-color-neutral-900);
    box-shadow: var(--btp-element-box-shadow);
    overflow-x: auto;

    &--medium {
      padding: var(--block-padding-medium);
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin-bottom: 20px;

      .btp-a {
        gap: 4px;
        font-size: 13px;
        font-family: var(--btp-font-heading);
        font-style: normal;
        font-weight: 500;
        line-height: 129.5%;
        color: var(--btp-color-primary-400);
      }

      &--pad-sides {
        padding-inline: 16px;
      }
    }

    &-description {
      padding: 0;
      width: 100%;

      &--pad-top {
        padding-bottom: 16px;
      }
    }

    iframe {
      max-width: 100%;
    }
  }

  &__centered-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    gap: var(--block-gap);
    max-width: 1480px;

    &--mobile {
      flex-direction: column;
    }

    &--desktop {
      display: none;
      grid-template-columns: fit-content(380px) auto;
      grid-auto-flow: column;
      margin-inline: auto;
      padding: 20px;
    }
  }

  &__desktop-sidebar {
    display: flex;
    flex-direction: column;
    gap: var(--block-gap);
  }

  &__desktop-flourishes {
    display: none;
    position: absolute;
    z-index: 0;

    &--gray {
      left: -550px;
      top: -200px;
    }

    &--orange {
      right: -400px;
      bottom: -300px;
      opacity: 0.9;
    }
  }

  &__desktop-content {
    display: flex;
    flex-direction: column;
    gap: var(--block-gap);
  }

  #block-views-partner-tiles-block,
  #block-views-partner-tiles-block .content,
  .collection-item,
  .collection-item p {
    padding: 0;
    margin: 0;
    max-width: 100%;
    min-height: auto;
    background-color: transparent;
    box-shadow: none;
  }

  .collection-item {
    border: none !important;
  }

  @media (min-width: 880px) {
    &__wrapper--mobile {
      display: none;
    }

    &__wrapper--desktop {
      display: grid;
    }
  }

  @media (min-width: 1080px) {
    --block-padding-small: var(--btp-element-padding-small-tablet);
    --block-padding-medium: var(--btp-element-padding-medium-tablet);
  }

  @media (min-width: 1280px) {
    --block-gap: 32px;
    --block-padding-small: var(--btp-element-padding-small);
    --block-padding-medium: var(--btp-element-padding-medium);

    &__desktop-flourishes {
      display: block;
    }

    &__wrapper--desktop {
      padding: 64px;
      grid-template-columns: fit-content(420px) auto;
    }

    &__desktop-content {
      display: grid;
      grid-template-rows: min-content min-content min-content min-content;
      grid-template-columns: 1fr 1fr;

      .btp-carousel {
        grid-column: 1 / span 2;
        grid-row: 1;
      }

      .btp-latest-news {
        grid-column: 1 / span 2;
        grid-row: 2;
      }

      .btp-webinars {
        grid-column: 2;
        grid-row: 3 / span 2;
      }

      .btp-twitter {
        grid-column: 1;
        grid-row: 4;
      }
    }
  }
}

@import "abstracts/index";
@import "blocks/index";
@import "layouts/index";

html {
  -webkit-font-smoothing: antialiased;
  scrollbar-gutter: stable;
  background-color: var(--btp-color-neutral-800);

  @media (max-width: 1080px) {
    &:has(.btp-mega-menu__mobile-container:not([inert])) {
      overflow: hidden;
    }
  }
}

html {
  &.logged-in,
  &:has(.admin-menu) {
    .btp-mega-menu__mobile-dropdown {
      min-height: calc(100dvh - 120px) !important;
    }
  }
}

.btp-mega-menu {
  display: flex;
  justify-content: center;
  align-items: center;

  dl {
    margin: 0 0 16px 0;

    dt {
      display: block;
      margin: 0 0 8px 0;
      padding: 12px 16px;
    }

    dd {
      margin: 0;
      padding: 0;
      font-weight: 400;
    }
  }

  &--mobile {
    dl {
      margin: 0;
      padding: 16px;
    }

    .btp-mega-menu__block {
      padding-block: 16px;
      gap: 0;

      &--main {
        background-color: var(--btp-color-neutral-900);
      }

      &--sub {
        padding: 20px;
        background-color: var(--btp-color-neutral-700);
      }
    }
  }

  &--desktop {
    display: none;
    gap: 4px;

    .btp-mega-menu__dropdown-button {
      svg {
        rotate: 90deg;
        transition: rotate var(--btp-transition-duration-short) ease-in-out;
      }

      &[aria-expanded="true"] {
        svg {
          rotate: -90deg;
        }
      }
    }

    .btp-mega-menu__block {
      &--main {
        grid-template-columns: 1fr 1fr;
        padding: 44px 64px;
      }

      &--sub {
        grid-template-columns: 1fr;
        background-color: var(--btp-color-neutral-800);
        padding: 45px 34px;
      }
    }
  }

  &__dropdown-button,
  &__link {
    display: flex;
    align-items: center;
    border-radius: var(--btp-element-border-radius);
    color: var(--btp-color-neutral-0);
    transition: color var(--btp-transition-duration-short) ease-in-out, background-color var(--btp-transition-duration-short) ease-in-out;
    cursor: pointer;

    &:link,
    &:visited {
      color: var(--btp-color-neutral-0);
    }
  }

  &__dropdown-button {
    padding: 10px 16px;
    justify-content: center;
    gap: 6px;
    user-select: none;
    box-sizing: border-box;

    .icon {
      fill: var(--btp-color-primary-400);
    }

    &:hover,
    &:focus-visible,
    &:focus {
      color: var(--btp-color-primary-400) !important;
      background-color: var(--btp-color-neutral-800);
      text-shadow: none;
    }

    svg {
      margin-top: 4px;
      width: 20px;
      height: 20px;
      filter: none;
    }

    &--user {
      border: none;
      color: var(--btp-color-neutral-900);
      background-color: transparent;

      &:hover,
      &:focus-visible,
      &:focus {
        .btp-user-badge {
          &::before {
            scale: 1.5;
          }
        }
      }

      svg {
        margin: 0;
      }

      .btp-user-badge {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 5px 0px;
        font-size: 16px;
        min-width: 32px;
        aspect-ratio: 1/1;
        font-weight: 300;
        color: var(--btp-color-neutral-900);
        text-shadow: none;
        background-color: var(--btp-color-primary-400);
        transition: background-color var(--btp-transition-duration-short) ease-in-out;

        &::before {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          border-radius: 100%;
          border: 1.5px solid var(--btp-color-neutral-900);
          width: 8px;
          aspect-ratio: 1/1;
          background-color: var(--btp-color-accent-green-500);
          transition: scale var(--btp-transition-duration-short) ease-in-out;
        }
      }
    }
  }

  &__link {
    justify-content: space-between;
    gap: 12px 16px;
    box-sizing: border-box;
    padding: 12px 16px;
    width: 100%;
    min-height: 20px;
    font-size: 15px;
    font-weight: 400;
    color: var(--btp-color-neutral-300);

    &:hover,
    &:focus-visible,
    &:focus {
      color: var(--btp-color-primary-400) !important;
      background-color: var(--btp-color-neutral-800);

      .btp-icon {
        translate: 0;
        opacity: 1;
      }
    }

    .btp-icon {
      translate: -4px;
      opacity: 0;
      transition: translate var(--btp-transition-duration-short) ease-in-out,
      opacity var(--btp-transition-duration-short) ease-in-out;
    }

    &--sub {
      align-self: stretch;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px 8px 8px;
      min-height: 56px;
      font-weight: 700;

      &:hover,
      &:focus-visible {
        color: var(--btp-color-neutral-0) !important;
        background-color: var(--btp-color-neutral-900) !important;

        .btp-mega-menu__sub-icon-container {
          &::before {
            opacity: 1;
          }

          .btp-icon path {
            stroke: var(--btp-color-neutral-900);
          }
        }
      }

      .btp-mega-menu__sub-title-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }

      .btp-icon {
        width: 22px;
        height: 22px;

        path {
          stroke: var(--btp-color-primary-500);
        }
      }
    }

    &--go-back {
      margin: 0 0 8px 0;
      color: var(--btp-color-primary-400) !important;

      .btp-mega-menu__sub-title-container {
        gap: 8px;
      }

      .btp-icon {
        width: 20px;
        height: 20px;
        opacity: 1;
        translate: 0 !important;
        rotate: 180deg;
        filter: none;
      }
    }
  }

  &__sub-icon-container {
    position: relative;
    border-radius: 2px;
    padding: 9px;
    width: 40px;
    aspect-ratio: 1/1;
    background-color: var(--btp-color-neutral-900);
    overflow: hidden;

    .btp-icon {
      position: relative;
      z-index: 1;
      opacity: 1;
      translate: 0;

      path {
        transition: stroke var(--btp-transition-duration-short) ease-in-out;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      inset: 0;
      background-color: var(--btp-color-primary-500);
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity var(--btp-transition-duration-short) ease-in-out;
    }
  }

  &__dropdown,
  &__slideout,
  &__mobile-dropdown {
    position: absolute;
    top: calc(100% + 3px);
    width: 100%;
    transform-origin: center right;
    transition: opacity var(--btp-transition-duration-short) ease-in-out,
    translate var(--btp-transition-duration-medium) ease-in-out,
    scale var(--btp-transition-duration-medium) ease-in-out;

    &[inert] {
      opacity: 0;
    }
  }

  &__slideout {
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--btp-color-neutral-900);

    &[inert] {
      translate: 100%;
      scale: 0 1;
    }
  }

  &__dropdown {
    display: grid;
    grid-template-columns: 2fr 1fr;
    left: 50%;
    max-width: 1550px;
    translate: -50%;
    background-color: var(--btp-color-neutral-900);
    box-shadow: 0 8px 24px -3px rgba(16, 24, 40, 0.10);

    &[inert] {
      translate: -50% -5%;
    }

    &--user {
      left: auto;
      right: 28px;
      grid-template-columns: 1fr;
      max-width: 250px;
      translate: 0;

      &[inert] {
        translate: 0 -5%;
      }
    }
  }

  &__block {
    display: grid;
    justify-content: stretch;
    align-items: flex-start;
    gap: 32px;

    &--sub {
      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  &__mobile-button {
    display: grid;
    margin: 0;
    border: none;
    padding: 0;
    width: 36px;
    aspect-ratio: 1/1;
    background-color: transparent;

    &:hover,
    &:focus,
    &:focus-visible {
      border: none;
      background-color: transparent;
    }

    svg {
      grid-row: 1;
      grid-column: 1;
      margin: 0;
      width: 100%;
      height: 100%;
      filter: none;
      transition: opacity var(--btp-transition-duration-short) ease-in-out;
    }

    &[aria-expanded="false"] {
      .hamburger-menu-icon {
        opacity: 1;
      }

      .close-menu-icon {
        opacity: 0;
      }
    }

    &[aria-expanded="true"] {
      .hamburger-menu-icon {
        opacity: 0;
      }

      .btp-user-badge {
        opacity: 0;
      }

      .close-menu-icon {
        opacity: 1;
      }
    }
  }

  &__mobile-dropdown {
    box-sizing: border-box;
    padding: 20px;
    left: 0;
    min-height: calc(100dvh - 92px);
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--btp-color-neutral-700);

    &[inert] {
      translate: 0 -5%;
    }

    .btp-mega-menu__dropdown-button {
      justify-content: space-between;
      width: 100%;
      min-height: 50px;
    }
  }

  @media (min-width: 1080px) {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
    }
  }
}

.btp-user-menu {
  &--desktop {
    display: none;

    .btp-mega-menu__dropdown-button {
      margin: 0;
      padding: 0;
      background-color: transparent !important;

      svg {
        transition: rotate var(--btp-transition-duration-short) ease-in-out;
      }

      &[aria-expanded="true"] {
        svg {
          rotate: -180deg;
        }
      }
    }

    .btp-mega-menu__block--user-menu {
      padding: 16px;
    }
  }

  &--mobile {
    display: flex;

    .btp-user-badge {
      grid-column: 1;
      grid-row: 1;
    }
  }

  .btp-mega-menu__block--user-menu {
    gap: 0;

    .btp-mega-menu__link {
      display: flex;
      padding: 12px 16px;
      align-items: center;
      gap: 8px;
      justify-content: flex-start;
      transition: color var(--btp-transition-duration-short) ease-in-out, background-color var(--btp-transition-duration-short) ease-in-out;

      .btp-icon {
        margin: 0;
        opacity: 1;
        translate: 0;

        path {
          stroke: var(--btp-color-neutral-600);
          transition: stroke var(--btp-transition-duration-short) ease-in-out;
        }
      }

      &:hover,
      &:focus-visible,
      &:focus {
        color: var(--btp-color-primary-400);

        path {
          stroke: var(--btp-color-primary-400);
        }
      }
    }
  }

  @media (min-width: 1080px) {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: flex;
    }
  }
}


.menu-render-newline {
  .btp-mega-menu__mobile-dropdown {
    min-height: calc(100dvh - 120px);
  }
}

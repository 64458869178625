@font-face {
  font-family: 'Open Sans';
  src: url('/sites/all/themes/ruckus/fonts/OpenSans.woff2') format('woff2');
  font-weight: 300 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/sites/all/themes/ruckus/fonts/Montserrat.woff2') format('woff2');
  font-weight: 300 700;
  font-style: normal;
}

/* Hard resets since we don't have access to the CSS src. */
.btp-h1,
.btp-h2,
.btp-h3,
.btp-h4,
.btp-h5,
.btp-h6,
.btp-a,
.btp-p {
  all: unset;
}

.btp-h1,
.btp-h5,
.btp-h6 {
  font-family: var(--btp-font-heading);
  font-style: normal;
  line-height: 120%;

  &--bold {
    font-weight: 700;
  }
}

/* Typography */
.btp-h1 {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.8px;
  color: var(--btp-color-neutral-0);
}

.btp-h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.32px;
  color: var(--btp-color-neutral-0);
}

.btp-h6 {
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.28px;
  color: var(--btp-color-neutral-0);

  &--desktop {
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  &--bold {
    font-weight: 700;
  }
}

.btp-p {
  font-family: var(--btp-font-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;

  &--xxs {
    font-size: 12px;
  }

  &--xs {
    font-size: 14px;
  }

  &--sm {
    font-size: 15px;
  }

  &--normal {
    font-weight: 400;
  }

  &--light {
    font-weight: 300;
  }

  &--semi {
    font-weight: 500;
  }
}

.btp-a {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:link,
  &:visited {
    color: var(--btp-color-primary-500);
    text-decoration: none;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

/* Orange Partners Sidebar Nav */
.btp-partners-nav {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: var(--btp-element-padding-small);
    width: 100%;
    background: var(--btp-element-gradient);
  }

  &__link {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    padding: 20px 16px;
    min-height: 60px;
    transition: background-color var(--btp-transition-duration-short) ease-in-out,
    border-radius var(--btp-transition-duration-short) ease-in-out,
    border-color var(--btp-transition-duration-short) ease-in-out;

    &:not(:first-of-type) {
      border-color: rgba(255, 255, 255, 0.20);
    }

    &:link,
    &:visited {
      color: var(--btp-color-neutral-900);
    }

    &:hover,
    &:focus-visible {
      border-radius: var(--btp-element-border-radius);
      border-color: transparent;
      background: #DD6E0080;
      cursor: pointer;

      + .btp-partners-nav__link {
        border-color: transparent;
      }

      svg {
        translate: 0;
        opacity: 1;
      }
    }

    svg {
      translate: -4px;
      opacity: 0;
      transition: translate var(--btp-transition-duration-short) ease-in-out,
      opacity var(--btp-transition-duration-short) ease-in-out;

      path {
        stroke: currentColor;
      }
    }
  }
}

.btp-popular-downloads {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 6px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    margin: 0;
  }

  &__link {
    box-sizing: border-box;
    display: grid;
    grid-template: min-content min-content / min-content 1fr;
    gap: 6px 12px;
    border-radius: var(--btp-element-border-radius);
    padding: 12px 16px;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    transition: color var(--btp-transition-duration-short) ease-in-out,
    background-color var(--btp-transition-duration-short) ease-in-out;

    &:link,
    &:visited {
      color: var(--btp-color-neutral-0);
    }

    &:hover,
    &:focus-visible,
    &:focus {
      color: var(--btp-color-primary-500);
      background-color: var(--btp-color-neutral-800);
      text-decoration: none;

      .btp-webinars__title {
        text-decoration: none;
      }
    }

    img {
      width: 24px;
      max-width: 24px;
    }
  }

  .btp-icon {
    grid-row: 1 / span 2;
    grid-column: 1;
  }

  &__list-date {
    grid-row: 2;
    grid-column: 2;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--btp-color-neutral-600);
  }

  @media (max-width: 800px) {
    &__list-item:nth-child(n + 9) {
      display: none;
    }
  }
}

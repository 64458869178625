.btp-podcast {
  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 16px;
  }

  &__logo-image {
    width: 43px;
    height: 43px;
  }

  &__platforms-image {
    width: 144px;
    height: 22px;
  }
}

.btp-newsletter {
  &__container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px 16px;
    box-sizing: border-box;
    border-radius: var(--btp-element-border-radius);
    padding: 12px 16px;
    width: 100%;
    min-height: 20px;
    transition: background-color var(--btp-transition-duration-short) ease-in-out,
    color var(--btp-transition-duration-short) ease-in-out;

    &:link,
    &:visited {
      color: var(--btp-color-neutral-0);
    }

    &:hover,
    &:focus-visible {
      color: var(--btp-color-primary-500);
      background: var(--btp-color-neutral-800);
      text-decoration: none;
      cursor: pointer;

      svg {
        translate: 0;
        opacity: 1;
      }
    }

    svg {
      translate: -4px;
      opacity: 0;
      transition: translate var(--btp-transition-duration-short) ease-in-out,
      opacity var(--btp-transition-duration-short) ease-in-out;
    }
  }
}

/* Webinars */
.btp-webinars {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__list-item {
    margin: 0;
    width: 100%;
  }

  &__link {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 6px;
    border-radius: var(--btp-element-border-radius);
    padding: 12px 16px;
    background-color: transparent;
    transition: color var(--btp-transition-duration-short) ease-in-out,
    background-color var(--btp-transition-duration-short) ease-in-out;

    &:link,
    &:visited {
      color: var(--btp-color-neutral-0);
    }

    &:hover,
    &:focus-visible,
    &:focus {
      color: var(--btp-color-primary-500);
      background-color: var(--btp-color-neutral-800);
      text-decoration: none;

      .btp-webinars__title {
        text-decoration: none;
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--btp-color-neutral-600);
  }

  @media (max-width: 800px) {
    &__list-item:nth-child(n + 9) {
      display: none;
    }
  }
}

.btp-partners-header {
  position: relative;
  box-sizing: border-box;
  z-index: 99998;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid var(--btp-color-primary-500);
  width: 100%;
  background-color: var(--btp-color-neutral-900);

  &__nav {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 28px;
    max-width: 1550px;
    min-height: 90px;
  }

  &__logo {
    height: 50px;
  }
}

.btp-partners-footer {
  position: relative;
  width: 100%;
  min-height: 400px;
  color: var(--btp-color-neutral-0);
  background: var(--btp-color-neutral-800);

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin: 0 auto;
    padding: 32px 20px;
    gap: 32px;
    max-width: 1480px;
  }

  &__link {
    &.btp-a {
      &:link,
      &:visited {
        color: var(--btp-color-neutral-0);
        text-decoration: none;
        transition: color var(--btp-transition-duration-short) ease-in-out;
        cursor: pointer;
      }

      &:hover {
        color: var(--btp-color-primary-500);
        text-decoration: underline;
      }
    }

    &--menu {
      line-height: 250%;
    }
  }

  .btp-h5 {
    line-height: 200%;
  }

  &__block {
    flex: 1 0 0;
    margin: 0;

    &:last-child {
      flex: 1.5 0 0;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;
  }

  .region-foot-about {
    margin-block: 28px;
    max-width: 350px;
  }

  form input:is([type="search"], [type="text"]) {
    display: flex;
    align-self: stretch;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--btp-color-neutral-700);
    padding: 5px var(--spacing-xs, 10px);
    height: 36px;
    color: var(--btp-color-neutral-600);
    font-family: var(--btp-font-body);
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 129.5%;
    background-color: var(--btp-color-neutral-900);

    &::placeholder {
      font-family: var(--btp-font-body);
    }
  }

  &__legal {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 20px;
  }

  @media (min-width: 880px) {
    &__wrapper {
      flex-direction: row;
      padding: 48px 20px;
    }
  }

  @media (min-width: 1280px) {
    &__wrapper {
      padding: 64px;
    }
  }
}

.btp-carousel {
  width: 100%;
  border: 1px solid var(--btp-color-neutral-700);
  background-color: var(--btp-color-neutral-900);

  &__component {
    position: relative;
  }

  &__slide-track {
    display: flex;
    overflow: hidden;
    aspect-ratio: 3/1;
  }

  &__title-track {
    display: grid;
    justify-content: flex-start;
    align-items: center;
  }

  &__title {
    grid-column: 1;
    grid-row: 1;
    text-align: center;
    transition: opacity var(--btp-transition-duration-short) ease-in-out;

    &[inert] {
      scale: 0;
      opacity: 0;
    }
  }

  &__slide {
    flex: 1 0 100%;
    display: block;
    width: 100%;
    height: 100%;

    a {
      display: block;
      width: 100%;
      height: 100%;

      &:focus-visible {
        outline: 2px dotted var(--btp-color-primary-400);
        outline-offset: -2px;
      }
    }

    img {
      height: 100%;
      object-fit: cover;

      &.contain-image {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &__bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 20px;
    min-height: 64px;
  }

  &__controls {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 16px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      border-radius: 2px;
      border: none;
      padding: 4px;
      min-width: 20px;
      aspect-ratio: 1/1;
      color: transparent;
      background-color: var(--btp-color-neutral-800);
      text-shadow: none;
      font-size: 0;
      transition: background-color var(--btp-transition-duration-short) ease-in-out,
      scale var(--btp-transition-duration-short) ease-in-out;

      svg {
        color: var(--btp-color-primary-400);
        width: 20px;
        height: 20px;
        filter: none;
        transition: color var(--btp-transition-duration-short) ease-in-out;
      }

      &[aria-selected="true"] {
        background-color: var(--btp-color-neutral-600);
        scale: 1.1;
      }

      &:hover,
      &:focus-visible {
        background-color: var(--btp-color-neutral-600);

        svg {
          color: var(--btp-color-neutral-900);
        }
      }

      &:focus-visible {
        outline: 2px dotted var(--btp-color-primary-400);
      }
    }
  }

  @media (min-width: 1080px) {
    max-width: 1028px;

    &__slide-track {
      aspect-ratio: 4/1;
    }

    &__bar {
      flex-direction: row;
      gap: 24px;
      padding: 8px 20px;
    }

    &__title {
      text-align: left;
    }
  }
}
